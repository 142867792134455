var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("WriteHanziPage", {
        attrs: {
          strokeStepImgList: _vm.strokeStepImgList,
          rightImgList: _vm.rightImgList,
          hanziInfo: _vm.hanziInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }