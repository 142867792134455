<template>
  <div class="game-container">
    <WriteHanziPage
      :strokeStepImgList="strokeStepImgList"
      :rightImgList="rightImgList"
      :hanziInfo="hanziInfo"
    />
  </div>
</template>

<script>
import WriteHanziPage from "@/components/Course/CoursePage/WriteHanziPage";
export default {
  data() {
    return {
      rightImgList: [
        // require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/tooth-1.svg`),
        require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/tooth-2.svg`),
        require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/tooth-3.svg`),
        require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/tooth-4.svg`),
      ],
      hanziInfo: {
        hanziBgImg: require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/hanzi-ba-grey-border.svg`),
        strokeRemindImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/hanzi-ba-stroke-order.svg"),
        pinyin:"bā"
      },
      strokeStepImgList: [
        require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/lesson-06-ba-1.gif`),
        require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/lesson-06-ba-2.gif`),
      ],
    };
  },
  components: {
    WriteHanziPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











