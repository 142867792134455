<template>
  <div class="lesson-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";
export default {
  data() {
    return {
      positionStr: "lesson",
      pageInfoObj: {
         pinyin: "Wǒ de xuéxiào",
        hanzi: "我的学校",
        english: "My School",
        sound: require("@/assets/audio/L1/1-Chapter/chapter-1-title.mp3"),
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>
